import * as React from 'react';
import { ActionButton } from '../../../components/action-button/ActionButton';
import { QuestionHeader } from '../../../components/currentQuestion/QuestionHeader';
import {
  useError,
  useTakeLivePollState,
} from '../../../contexts/takeLivePollStateContext';
import { IQuestion } from '../../../models/Question';
import { DisplayComments } from './DisplayComments';
import Style from './LiveFeedQuestionStarted.module.scss';
import ProgressBar from '../../../components/progressbar/ProgressBar.module';

interface Props {
  question: IQuestion;
  submitLiveFeedComment(questionId: string, text: string): void;
}

export const LiveFeedQuestionStarted = ({
  question,
  submitLiveFeedComment,
}: Props) => {
  const [text, setText] = React.useState<string>('');

  const { serverState } = useTakeLivePollState();
  const error = useError();

  const textChange = (event: any) => {
    setText(event.target.value);
    const textarea = document.getElementById('textArea')!;
    textarea.style.height = Math.min(textarea.scrollHeight - 4) + 'px';
    if (event.target.value === '') {
      textarea.style.height = '25px';
    }
  };

  const onSubmitClicked = async () => {
    await submitLiveFeedComment(question.id, text);
    setText('');
  };

  if (error) {
    return <DisplayComments />;
  }

  return (
    <>
      {!serverState.liveFeedQuestionResponse?.myComment && (
        <div className={Style.container}>
          <div className={Style.content}>
            <QuestionHeader question={question} />
            <ProgressBar />

            <div className={Style.textInputAndBtnContainer}>
              <div className={Style.textInputContainer}>
                <textarea
                  id={'textArea'}
                  name="comment"
                  className={Style.textInput}
                  placeholder={'Write your answer here...'}
                  rows={1}
                  maxLength={255}
                  value={text}
                  onChange={textChange}
                />
              </div>

              <div className={Style.sendButtonAndCharacterRow}>
                <div className={Style.textLength}>
                  <span>{text.length}/255 characters</span>
                </div>

                <ActionButton
                  className={
                    Style.sendButton +
                    ' ' +
                    (text.length === 0 ? Style.hideButton : '')
                  }
                  data-testid="sendButton"
                  onClick={onSubmitClicked}
                  showLoader={true}
                >
                  Send
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {serverState.liveFeedQuestionResponse?.myComment && <DisplayComments />}
    </>
  );
};
